import { Chart, DoughnutController, ArcElement, BarController, BarElement, Tooltip, CategoryScale, LinearScale, Legend } from 'chart.js';

$(function () {
  // PORTFOLIO LOAD MORE
  $("#projekte li:lt(6)").show();
  var shown = 6;
  $("#mehrLaden").on("click", function () {
    shown = $("#projekte li:visible").length + 9;
    if (shown < items) {
      $("#projekte li:lt(" + shown + ")").show();
    } else {
      // eslint-disable-next-line no-undef
      $("#projekte li:lt(" + items + ")").show();
      $("#mehrLaden").hide();
    }
  });
  // CHARTS.JS
  var $id = function (id) {
    return document.getElementById(id);
  };

  Chart.register(DoughnutController, ArcElement, BarController, BarElement, Tooltip, CategoryScale, LinearScale, Legend);
  Chart.defaults.font.family = "'CorporateS-Regular', 'Arial', 'Helvetica', sans-serif";
  Chart.defaults.font.size = 14;
  Chart.defaults.plugins.tooltip.titleFont.family = "'CorporateS-Regular', 'Arial', 'Helvetica', sans-serif";

  // CHARTS.JS BALKENDIAGRAMM

  var canvas = $id("funfacts"),
    ctx = canvas.getContext("2d"),
    microBar = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "Verschlissene Praktikanten",
          "Securityeinsätze von Emmy",
          "Kaffee pro Woche",
          "Projekte seit 2007",
        ],
        datasets: [{
          barPercentage: 0.90,
          categoryPercentage: 1,
          // eslint-disable-next-line no-undef
          data: [praktikanten, emmy, kaffee, projekte],
          backgroundColor: "rgba(255,255,255,1)",
          borderColor: "rgba(255,255,255,1)",
          hoverBackgroundColor: "rgba(255,255,255,0.8)",
        }, ],
      },
      options: {
        maintainAspectRatio: false,
        //responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: function (context) {
                return context.label + ": " + context.formattedValue;
              },
              title: function (tooltipItem, data) {
                return;
              },
            },
            bodyAlign: "center",
            xAlign: "center",
            yAlign: "bottom"
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white",
              autoSkip: false,
              //minRotation: 85,
              //minRotation: 90,
              minRotation: function () {
                var width = $(window).width();
                if(width<=641)
                  return 90;
                else
                  return 0;
              },
              maxRotation: 90,
            },
            grid: {
              color: "rgba(255,255,255,0.2)",
            },
          },
          y: {
            ticks: {
              color: "white",
              beginAtZero: true,
              stepSize: 25
            },
            grid: {
              color: "rgba(255,255,255,0.2)",
            },
          },
        },
        animation: false,
      },
    });

  // CHARTS.JS Doughnut

  var doughnutCanvas = $id("doughnut");

  var moduleDoughnut = new Chart(doughnutCanvas.getContext("2d"), {
    type: "doughnut",
    data: {
      labels: ["Konsumgüter", "Handel", "Verbände", "Dienstleister"],
      datasets: [{
        data: [250, 200, 100, 150],
        backgroundColor: ["#FF5400", "#d93400", "#9f2900", "#772100"],
        borderColor: ["white", "white", "white", "white"],
      }, ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "right",
          align: "center",
          labels: {
            usePointStyle: true,
            fontColor: "black",
            padding: function (context) {
              var width = context.chart.width;
              var size = Math.round(width / 20);
              return size;
            },
            font: function (context) {
              var width = context.chart.width;
              var size = Math.round(width / 24);
              return {
                size: size,
              };
            },
          },
          onClick: null,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function (context) {
              return context.label;
            },
          },
          bodyAlign: "center",
          xAlign: "center",
          yAlign: "bottom"
        },
      },
      animation: false,
    },
  });

  //redraw charts after fonts loaded
  document.fonts.onloadingdone = function () {
    microBar.update();
    moduleDoughnut.update();
  };
});